import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-12 px-10 left-0 z-100 w-full bg-green-secondary" }
const _hoisted_2 = { class: "flex items-center justify-between text-white text-xs" }
const _hoisted_3 = { class: "flex max-w-2xl items-center gap-2" }
const _hoisted_4 = { class: "flex flex-row items-center justify-end h-full" }
const _hoisted_5 = { class: "mr-3" }
const _hoisted_6 = ["src"]

import { SYSTEM } from "@ems/locales/system";

export default /*@__PURE__*/_defineComponent({
  __name: 'TheFooter',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", null, [
          _createElementVNode("p", null, _toDisplayString(_unref(SYSTEM).platform_services_agreement), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider-circle" }, null, -1)),
        _createElementVNode("button", null, [
          _createElementVNode("p", null, _toDisplayString(_unref(SYSTEM).privacy_policy), 1)
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "divider-circle" }, null, -1)),
        _createElementVNode("button", null, [
          _createElementVNode("p", null, _toDisplayString(_unref(SYSTEM).platform_fees), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, " ©" + _toDisplayString(new Date().getFullYear()) + " REDEX All Right Reserved ", 1),
        _createElementVNode("img", {
          class: "h-12",
          src: require('@/assets/images/logos/REDEX-footer-logo.png'),
          alt: "t-recs logo"
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}
}

})