import { defineComponent as _defineComponent } from 'vue'
import { reactive } from "vue";

interface Props {
  fullScreen?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSpinner',
  props: {
    fullScreen: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { fullScreen } = reactive(props);

const __returned__ = { props, fullScreen, reactive }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})