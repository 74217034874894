import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";

import TheAvatar from "./TheAvatar.vue";

import AccountModule from "@/store/modules/Account";


export default /*@__PURE__*/_defineComponent({
  __name: 'TheHeader',
  setup(__props, { expose: __expose }) {
  __expose();

const isBOMSApp = process.env.VUE_APP_TYPE === "app-boms";

const user = computed(() => AccountModule.dataAccountProfileGetter);
const getNameUser = (): string => {
  if (!user.value?.FirstName && !user.value?.LastName) {
    return user.value?.UserName;
  }
  if (!user.value?.FirstName && user.value?.LastName) {
    return user.value?.LastName;
  }
  if (user.value?.FirstName && !user.value?.LastName) {
    return user.value?.FirstName;
  }
  return user.value?.FirstName + user.value?.LastName;
};

const __returned__ = { isBOMSApp, user, getNameUser, computed, TheAvatar, get AccountModule() { return AccountModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})