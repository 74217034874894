import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href"]

import { computed, toRefs } from "vue";

interface Props {
  name: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSvgIcon',
  props: {
    name: {}
  },
  setup(__props: any) {

const props = __props;
const { name } = toRefs(props);

const iconPath = computed(() => {
  if (name.value) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    let icon = require(`@/assets/icons/${name.value}.svg`);
    if (Object.prototype.hasOwnProperty.call(icon, "default")) {
      icon = icon.default;

      return icon.url;
    }
  }

  return null;
});
const className = computed(
  () => `inline-block fill-current svg-icon--${name.value}`
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(className.value),
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("use", {
      "xlink:href": iconPath.value,
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }, null, 8, _hoisted_1)
  ], 2))
}
}

})