import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, onUnmounted, ref } from "vue";
import AccountModule from "@/store/modules/Account";
import { formatUTCToLongLocalDate } from "@/utils/helpers/convertDateTime";
import { useRoute } from "vue-router";
import { getRole } from "@/utils/storage";
import { OPS_ADMIN } from "@ems/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'TheClock',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const companyName = computed(
  () => AccountModule.dataAccountProfileGetter.CompanyName
);
const truncatedCompanyName = computed(() =>
  companyName.value ? companyName.value.slice(0, 50) : ""
);
const interval = ref();
const isRegistrationRoute = ref(false);
const isAdmin = ref(false);
const clock = ref();

onMounted(() => {
  isRegistrationRoute.value = ["tenant/create", "tenant/waiting-approval"].some(
    (path) => route.path.includes(path)
  );

  if (getRole() == OPS_ADMIN) {
    isAdmin.value = true;
  }

  interval.value = setInterval(() => {
    clock.value = Date.now();
  }, 1000);
});

onUnmounted(() => {
  if (interval.value) clearInterval(interval.value);
});

const __returned__ = { route, companyName, truncatedCompanyName, interval, isRegistrationRoute, isAdmin, clock, computed, onMounted, onUnmounted, ref, get AccountModule() { return AccountModule }, get formatUTCToLongLocalDate() { return formatUTCToLongLocalDate }, get useRoute() { return useRoute }, get getRole() { return getRole }, get OPS_ADMIN() { return OPS_ADMIN } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})