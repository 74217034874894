import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
// import ButtonToggleSidebar from "./ButtonToggleSidebar.vue";
import Navigation from "./Navigation.vue";

// const store = useStore();

// const toggle = computed(() => store.state.LocalStorage.UI.isToggleSidebar);

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

// import { useStore } from "vuex";
const toggle = computed(() => true);

const __returned__ = { toggle, computed, Navigation }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})