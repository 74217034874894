import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "page-container relative bg-neutral-light" }
const _hoisted_2 = {
  class: "absolute -top-1 mt-2 right-12 text-gray-400 z-100",
  "data-aos": "fade-left",
  "data-aos-duration": "1000"
}
const _hoisted_3 = { class: "bg-neutral-light" }

import { onMounted, ref, computed } from "vue";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";

import TheMenuSideBar from "@/components/TheMenuSideBar/index.vue";
import TheClock from "@/components/TheClock.vue";

import BaseSpinner from "@/components/BaseSpinner.vue";
import AccountModule from "@/store/modules/Account";
import { getTenantId } from "@/utils/storage";


export default /*@__PURE__*/_defineComponent({
  __name: 'TheDefault',
  setup(__props) {

const isLoading = ref(true);

const toggle = computed(() => true);

onMounted(async () => {
  setTimeout(() => {
    isLoading.value = false;
  }, 200);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isLoading.value)
      ? (_openBlock(), _createBlock(TheMenuSideBar, {
          key: 0,
          class: "lg:flex"
        }))
      : _createCommentVNode("", true),
    (!isLoading.value)
      ? (_openBlock(), _createElementBlock("main", {
          key: 1,
          class: _normalizeClass([
      'page',
      toggle.value ? 'menu-close small-screen-menu-close' : 'menu-open',
    ])
        }, [
          _createVNode(TheHeader),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(TheClock)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ]),
          _createVNode(TheFooter, { class: "page-footer" })
        ], 2))
      : (_openBlock(), _createBlock(BaseSpinner, {
          key: 2,
          fullScreen: ""
        }))
  ], 64))
}
}

})