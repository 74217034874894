import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-neutral-dark" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (!$setup.isRegistrationRoute && $setup.companyName?.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          ($setup.companyName.length <= 50)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Hello " + _toDisplayString($setup.companyName) + " " + _toDisplayString($setup.isAdmin ? "user" : "") + ", ", 1))
            : (_openBlock(), _createBlock(_component_el_popover, {
                key: 1,
                placement: "bottom",
                width: 400,
                trigger: "hover",
                content: $setup.companyName,
                "popper-class": "max-w-md max-h-30 whitespace-normal break-words"
              }, {
                reference: _withCtx(() => [
                  _createElementVNode("span", null, " Hello " + _toDisplayString($setup.truncatedCompanyName) + _toDisplayString($setup.isAdmin ? " user" : "") + "... ", 1)
                ]),
                _: 1
              }, 8, ["content"]))
        ]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString($setup.formatUTCToLongLocalDate($setup.clock)), 1)
  ]))
}