import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from "vue";
import BaseSpinner from "@/components/BaseSpinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TheBlank',
  setup(__props) {

const isLoading = ref(true);

onMounted(() => {
  setTimeout(() => {
    isLoading.value = false;
  }, 200);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", null, [
    (!isLoading.value)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : (_openBlock(), _createBlock(BaseSpinner, {
          key: 1,
          fullScreen: ""
        }))
  ]))
}
}

})