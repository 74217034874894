import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "animate__animated animate__fadeIn whitespace-nowrap w-6 h-6"
}

import BaseSvgIcon from "@/components/BaseSvgIcon.vue";

interface Props {
  icon?: string;
  toggle?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'LinkSidebar',
  props: {
    icon: {},
    toggle: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { class: "menu__sidebar-item hover:text-green-secondary rounded-tl-xl rounded-bl-xl group" }, {
    default: _withCtx(() => [
      (props.icon)
        ? (_openBlock(), _createBlock(BaseSvgIcon, {
            key: 0,
            class: "text-black group-hover:text-green-secondary w-6 h-6",
            name: props.icon
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      (!props.toggle)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})