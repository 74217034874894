import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import { ElMessage } from "element-plus";
import AccountModule from "@/store/modules/Account";

interface Props {
  size?: string;
  rounded?: boolean;
  name: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TheAvatar',
  props: {
    size: { default: "40" },
    rounded: { type: Boolean, default: false },
    name: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const avatarLetter = computed(() => {
  if (!props.name) return "";
  const str = props.name.split(" ");

  if (str.length === 1) {
    return props.name[0].toUpperCase();
  }

  return str.map((word) => word[0].toUpperCase()).join("");
});

const svgStyle = computed(() => {
  const letterIndex = avatarLetter.value.charCodeAt(0) - 64;
  const hue = (360 / 26) * letterIndex;
  const backgroundColor = `hsl(${hue}, 68%, 48%)`;
  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
    borderRadius: props.rounded ? "100%" : "0",
    background: backgroundColor,
  };
});

const textStyle = computed(() => ({
  fill: "rgba(255, 255, 255, .7)",
  fontFamily: "'Lucida Console', Monaco, monospace",
  fontSize: `${parseInt(props.size) * 0.65}px`,
}));

const handleCommand = (command: string) => {
  if (command === "logout") {
    AccountModule.logout();
  } else if (command === "changePassword") {
    // do nothing
  }
};

const __returned__ = { props, avatarLetter, svgStyle, textStyle, handleCommand, computed, get ElMessage() { return ElMessage }, get AccountModule() { return AccountModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})