import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    class: "rounded-lg",
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    width: "20%",
    center: "",
    "before-close": $setup.handleClose
  }, {
    title: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("h1", { class: "text-center text-2xl font-bold" }, "Logging out", -1)
    ])),
    footer: _withCtx(() => [
      _createElementVNode("div", { class: "flex justify-center items-center space-x-4" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--gray",
          onClick: $setup.handleClose
        }, " Cancel "),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--green-primary",
          onClick: $setup.handleConfirm
        }, " Confirm ")
      ])
    ]),
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "text-center 2xl:text-lg font-medium" }, "Are you sure?", -1))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}