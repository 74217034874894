import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-1" }
const _hoisted_2 = { class: "avatar-container" }
const _hoisted_3 = {
  key: 1,
  class: "text-black",
  fill: "currentColor",
  stroke: "currentColor",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    onCommand: $setup.handleCommand,
    trigger: "click"
  }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_dropdown_item, { disabled: "" }, {
            default: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-bold" }, "User Account", -1)),
              _createElementVNode("div", _hoisted_1, _toDisplayString($setup.props.name), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, { command: "changePassword" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Change Password")
            ])),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, { command: "logout" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Logout")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        ($setup.avatarLetter)
          ? (_openBlock(), _createElementBlock("svg", {
              key: 0,
              style: _normalizeStyle($setup.svgStyle)
            }, [
              _createElementVNode("text", {
                x: "50%",
                y: "50%",
                "text-anchor": "middle",
                "dominant-baseline": "central",
                style: _normalizeStyle($setup.textStyle)
              }, _toDisplayString($setup.avatarLetter), 5)
            ], 4))
          : (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[3] || (_cache[3] = [
              _createElementVNode("path", { d: "M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" }, null, -1)
            ])))
      ])
    ]),
    _: 1
  }))
}