import { defineComponent as _defineComponent } from 'vue'
import DialogModule from "@/store/UI/Dialog";
import AccountModule from "@/store/modules/Account";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'Logout',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();

const handleClose = () => {
  DialogModule.hideModal();
};

const handleConfirm = () => {
  DialogModule.hideModal();
  AccountModule.logout();
  return router.push("/login");
};

const __returned__ = { router, handleClose, handleConfirm, get DialogModule() { return DialogModule }, get AccountModule() { return AccountModule }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})