import { defineComponent as _defineComponent } from 'vue'
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import DialogLogout from "@/components/TheDialog/Logout.vue";
import DialogModule from "@/store/UI/Dialog";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import LinkSidebar from "./LinkSidebar.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Navigation',
  setup(__props, { expose: __expose }) {
  __expose();

const isBOMSApp = process.env.VUE_APP_TYPE === "app-boms";

const toggle = computed(() => true);
const route = useRoute();
const router = useRouter();

const handleLogout = () => {
  DialogModule.showModal({
    component: DialogLogout,
  });
};

const routes = computed((): any[] => {
  return (
    ((route.matched.length > 0 && route.matched[0].children) as any[]) || []
  );
});

const getSubPath = () =>
  route.matched[0].path !== "/" ? route.matched[0].path.split("/") : [];

const truthlyPath = (path: string): string => {
  const subPath = getSubPath();
  //const isInAdmin = subPath.includes("admin");
  if (subPath.length >= 2) {
    const fullPath = [];
    for (const p of subPath) {
      if (p) {
        const key = p.split(":");

        key.length > 1 && key[1]
          ? fullPath.push(route.params[key[1]])
          : fullPath.push(p);
      }
    }
    path && fullPath.push(path);
    // return `/${isInAdmin ? "admin/" : ""}${fullPath.join("/")}`;
    return `/${fullPath.join("/")}`;
  }
  return `/${path}`;
};

const __returned__ = { isBOMSApp, toggle, route, router, handleLogout, routes, getSubPath, truthlyPath, BaseSvgIcon, DialogLogout, get DialogModule() { return DialogModule }, computed, get useRoute() { return useRoute }, get useRouter() { return useRouter }, LinkSidebar }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})